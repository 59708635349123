import Button from './components/Button';

function App() {
  return (
    <div>
      <Button primary rounded>Click Me!</Button>
    </div>
  )
}

export default App;